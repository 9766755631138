import React from 'react'

import SEO from '../components/seo'
import Button from '../components/button'
import image from '../images/DWAYNE.jpg'

const NotFoundPage = () => (
  <>
    <SEO title='Siden finnes ikke.' />
    <div className='text-center max-w-screen-lg mb-20 mx-auto header-offset'>
      <div className='relative'>
        <div className='absolute w-full top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 px-5'>
          <h1 className='text-screen-2 lg:text-6xl mb-4 text-white'>Sorry, denne siden<br />finnes ikke lenger</h1>
        </div>

        <img src={image} alt='' className='mx-auto mb-8' />
      </div>

      <div className='px-5'>
        <p className='lead'>
          Du kan dessverre aldri dra tilbake til fortiden.<br />
          Men knappen under tar deg tilbake til forsiden.
        </p>

        <Button href='/'>Ta meg til forsiden</Button>
      </div>
    </div>
  </>
)

export default NotFoundPage
